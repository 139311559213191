import React, { FC, useContext, useEffect } from 'react'
import { navigate, PageProps } from 'gatsby'
import LayoutSplash from '@layouts/LayoutSplash'
import { Button, Box, Typography } from '@mui/material'
import { TextField, TextFieldPassword, Seo } from '@components'
import useForm from '@utils/hooks/useForm'
import AuthContext from '@utils/contexts/AuthContext'
import { DEFAULT_DASH_PATH, refreshData } from '@utils/auth'
import { useSnackbar } from 'notistack'
import { wasSuccess } from '@utils/hooks/useApi'

const LoginPage: FC<PageProps> = () => {
  const { doLogIn, isLoggedIn } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm({
    initialValues: {
      username: '',
      password: ''
    },
    initialValidationRules: {
      username: (value) => value.length > 0,
      password: (value) => value.length > 0
    }
  })

  useEffect(() => {
    ;(async () => {
      if (isLoggedIn) {
        const response = await refreshData()

        if (response && wasSuccess(response)) {
          navigate(DEFAULT_DASH_PATH)
          enqueueSnackbar('Logging you in automatically.', {
            variant: 'success'
          })
        }
      }
    })()
  }, [])

  return (
    <LayoutSplash>
      <Seo title={'Login'} />
      <Box>
        <Box pb={2}>
          <Typography color="textPrimary" variant="h2">
            Welcome to Sandfly Security
          </Typography>
          <Typography color="textPrimary" variant="h4" style={{ marginTop: 0 }}>
            Sign in to get started
          </Typography>
        </Box>
        <form
          data-cy="loginForm"
          noValidate
          onSubmit={form.onSubmit((values) => doLogIn(values))}
        >
          <Box mb={2}>
            <TextField id="username" label="Username" form={form} required />
          </Box>

          <Box mb={2}>
            <TextFieldPassword id="password" required form={form} />
          </Box>
          <Button
            id="submit"
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            color="primary"
          >
            {'Sign In'}
          </Button>
        </form>
      </Box>
    </LayoutSplash>
  )
}

export default LoginPage
